import axios from "axios";


const uploadToCloud = async (
    file,
    parentFolderId,
    subFolderName,
    nodate = 0,
    extensions = ["jpeg", "jpg", "png", "pdf", "gif", "csv", "xlsx", "docx", "txt", "mp4"],
    filesize = 5500000000,
) => {

    const formData = {
        'solutionnname': 'lms',
        'file': await file.arrayBuffer(),
        'name': file.name,
        'type': file.type,
        'size': file.size,
        parentFolderId,
        subFolderName,
        filesize,
        nodate,
        'extensions': JSON.stringify(extensions),
        'email_domain': process.env.IVYIS_EMAIL_DOMAIN,
    }

    const headers = {
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${process.env.IVYIS_CLOUD_KEY}`,
    };

    const link = await axios.post(`${process.env.IVYIS_CLOUD_DOMAIN}/googledrive/index.php`,
        formData,
        { headers }
    ).then(res => res.data.data.link)

    return link;
}


export default uploadToCloud