"use client"

import { SEO_API, SEO_API_KEY } from "@constants"
import { getDict, updateDict } from "@lib/reducers/dictReducer"
import { getLang } from "@lib/reducers/langReducer"
import { AxiosRequest, dev_console_log } from "@utils"
import { useDispatch, useSelector } from "react-redux"


const useTranslate = () => {
    const lang = useSelector(getLang)
    const dict = useSelector(getDict)
    const dispatch = useDispatch()

    const translate = (keyword) => {
        let translation = keyword

        if (dict) {
            const keywordTranslations = dict[keyword] || {}

            if (keywordTranslations[lang]) translation = keywordTranslations[lang]
            else AxiosRequest.post(`${SEO_API}/translation/addOrUpdateTrans`, {
                keyword, lang, solution: "lwe"
            }, {
                headers: {
                    API_KEY: SEO_API_KEY
                }
            }).then(res => {
                // upate translation
                translation = res.data[lang]

                // update store
                keywordTranslations[lang] = res.data[lang]
                dispatch(updateDict({ keyword, keywordTranslations }));
            }).catch(error => {
                dev_console_log(error.message);
            })
        }

        return translation
    }


    return translate
}

export default useTranslate