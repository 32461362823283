import { dev_console_log } from "@utils";

async function delayedFunction(handler, timeout, idx) {
    return await new Promise((resolve) => {
        setTimeout(() => {
            dev_console_log(`reached ${idx}`);
            const result = handler()
            resolve(result);
        }, timeout);
    });
}

const PromiseRetryRequests = (requestsHandlers, timeout = 1500, retryNumber = 0) => {
    if (retryNumber == 5) {
        dev_console_log(`Max retry count reached!, ${requestsHandlers.length} failed!`)
        return;
    }

    const executedRequests = requestsHandlers.map((handler, idx) => delayedFunction(handler, timeout * idx + 1, idx));

    Promise.allSettled(executedRequests).then(results => {
        let failedRequestsHandlers = []

        results.forEach((result, idx) => {
            if (result.status == 'rejected') failedRequestsHandlers.push(requestsHandlers[idx])
        })

        const requestsCount = requestsHandlers.length
        const failureCount = failedRequestsHandlers.length
        const successCount = requestsCount - failureCount
        dev_console_log(`${requestsCount} metadata requests finished. Success: ${successCount}, Failure: ${failureCount}`);

        if (failureCount > 0) {
            dev_console_log(`Retry ${retryNumber} on ${failureCount} failed requests`);
            PromiseRetryRequests(failedRequestsHandlers, timeout, retryNumber + 1)

            // setTimeout(() => {
            //     PromiseRetryRequests(failedRequestsHandlers, timeout, retryNumber - 1)
            // }, timeout)
        } else {
            dev_console_log(`All metadata requests completed successfully`);
        }
    });
}

export default PromiseRetryRequests