import { useState, useEffect } from "react";
import axios from "axios";
import { SEO_API } from "@constants";


const useBatchTranslate = (keywords, lang) => {
    const [batchDict, setBatchDict] = useState([]);

    useEffect(() => {
        const data = { keywords, lang };
        const config = { headers: { API_KEY: "" } };

        axios.post(`${SEO_API}/translation/translateBatch`, data, config).then(res => {
            setBatchDict(res.data)
        }).catch(err => {
            console.error(err.message)
        })
    }, []);


    const translate = keyword => {
        const translation = batchDict.find(item => item.keyword == keyword)
        return translation ? translation[lang] : keyword;
    }

    return translate
}

export default useBatchTranslate
