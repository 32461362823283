export const SEO_API_KEY = process.env.NEXT_PUBLIC_SEO_API_KEY
export const SEO_API = process.env.NEXT_PUBLIC_SEO_API
export const SOCKET_ROOT = process.env.NEXT_PUBLIC_SOCKET_ROOT
export const BASE_URL = process.env.NEXT_PUBLIC_CLIENT
export const BACKEND_ROOT = process.env.NEXT_PUBLIC_BACKEND_ROOT
export const AI_ROOT = process.env.NEXT_PUBLIC_AI_ROOT
export const Assessment_Url = process.env.NEXT_PUBLIC_ASSESSMENT_ROOT
export const Assessment_Backend = process.env.NEXT_PUBLIC_ASSESSMENT_BACKEND

export const allowedExtensionsCourseFiles = [
    'image/png', 'image/jpeg', 'image/jpg', 'video/mp4',
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    'application/pdf', 'text/plain',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    "application/x-zip-compressed"
]

export const PAYMOB_API_KEY = process.env.NEXT_PUBLIC_PAYMOB_API_KEY
export const PAYMOB_INTEGRATION_ID = process.env.NEXT_PUBLIC_PAYMOB_INTEGRATION_ID
export const PAYMOB_IFRAME_ID = process.env.NEXT_PUBLIC_PAYMOB_IFRAME_ID
export const PAYMOB_BASE_URL = process.env.NEXT_PUBLIC_PAYMOB_BASE_URL
export const PAYMOB_HMAC_SECRET = process.env.NEXT_PUBLIC_PAYMOB_HMAC_SECRET

export const FAWATERAK_BASE_URL = process.env.NEXT_PUBLIC_FAWATERAK_BASE_URL
export const FAWATERAK_VENDOR_KEY = process.env.NEXT_PUBLIC_FAWATERAK_VENDOR_KEY
export const FAWATERAK_PROVIDER_KEY = process.env.NEXT_PUBLIC_FAWATERAK_PROVIDER_KEY
export const LWE_HMAC = process.env.NEXT_PUBLIC_LWE_HMAC

export const stopwords = [
    // a
    `a`,
    `about`,
    `above`,
    `actually`,
    `after`,
    `again`,
    `against`,
    `all`,
    `almost`,
    `also`,
    `although`,
    `always`,
    `am`,
    `an`,
    `and`,
    `any`,
    `are`,
    `as`,
    `at`,
    // b
    `be`,
    `became`,
    `become`,
    `because`,
    `been`,
    `before`,
    `being`,
    `below`,
    `between`,
    `both`,
    `but`,
    `by`,
    // c
    `can`,
    `could`,
    // d
    `did`,
    `do`,
    `does`,
    `doing`,
    `down`,
    `during`,
    // e
    `each`,
    `either`,
    `else`,
    // f
    `few`,
    `for`,
    `from`,
    `further`,
    // h
    `had`,
    `has`,
    `have`,
    `having`,
    `he`,
    `he'd`,
    `he'll`,
    `hence`,
    `he's`,
    `her`,
    `here`,
    `here's`,
    `hers`,
    `herself`,
    `him`,
    `himself`,
    `his`,
    `how`,
    `how's`,
    // i
    `I`,
    `I'd`,
    `I'll`,
    `I'm`,
    `I've`,
    `if`,
    `in`,
    `into`,
    `is`,
    `it`,
    `it's`,
    `its`,
    `itself`,
    // j
    `just`,
    // l
    `let's`,
    // m
    `may`,
    `maybe`,
    `me`,
    `might`,
    `mine`,
    `more`,
    `most`,
    `must`,
    `my`,
    `myself`,
    // n
    `neither`,
    `nor`,
    `not`,
    // o
    `of`,
    `oh`,
    `on`,
    `once`,
    `only`,
    `ok`,
    `or`,
    `other`,
    `ought`,
    `our`,
    `ours`,
    `ourselves`,
    `out`,
    `over`,
    `own`,
    // s
    `same`,
    `she`,
    `she'd`,
    `she'll`,
    `she's`,
    `should`,
    `so`,
    `some`,
    `such`,
    // t
    `than`,
    `that`,
    `that's`,
    `the`,
    `their`,
    `theirs`,
    `them`,
    `themselves`,
    `then`,
    `there`,
    `there's`,
    `these`,
    `they`,
    `they'd`,
    `they'll`,
    `they're`,
    `they've`,
    `this`,
    `those`,
    `through`,
    `to`,
    `too`,
    // u
    `under`,
    `until`,
    `up`,
    // v
    `very`,
    // w
    `was`,
    `we`,
    `we'd`,
    `we'll`,
    `we're`,
    `we've`,
    `were`,
    `what`,
    `what's`,
    `when`,
    `whenever`,
    `when's`,
    `where`,
    `whereas`,
    `wherever`,
    `where's`,
    `whether`,
    `which`,
    `while`,
    `who`,
    `whoever`,
    `who's`,
    `whose`,
    `whom`,
    `why`,
    `why's`,
    `will`,
    `with`,
    `within`,
    `would`,
    // y
    `yes`,
    `yet`,
    `you`,
    `you'd`,
    `you'll`,
    `you're`,
    `you've`,
    `your`,
    `yours`,
    `yourself`,
    `yourselves`
]