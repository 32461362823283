import axios from "axios"


const sendEmail = async (from, to, subject, body, attachments=[], bcc=[], cc=[]) => {
    try {
        const res = await axios.post(`${process.env.IVYIS_CLOUD_DOMAIN}/sendmail/index.php`,
            {
                solutionnname: "lms",
                from: from,
                to: to,
                subject: subject,
                body: body,
                attachments: attachments,
                bcc: bcc,
                cc: cc
            },
            {
                headers: {
                    Authorization: `Bearer ${process.env.IVYIS_CLOUD_KEY}`,
                }
            }
        )

        return res.data
    } catch (error) {
        console.error("Error sending mail:", error)
        throw error
    }
}


export default sendEmail