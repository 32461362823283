import axios from "axios";
import { AI_ROOT } from "@constants";


export const buildCourseMindMapAsync = async (course) => {
    const data = {
        LOs: course.learningOutcomes.map(lo => {
            return {
                name: lo.statement,
                learning_targets: lo.performanceCriteria.map(lt => lt.description)
            }
        }),
        course: course.title,
        solutionnname: "lms"
    }

    const config = {
        headers: {
            Authorization: `Bearer ${process.env.IVYIS_CLOUD_KEY}`,
        }
    }

    const mindMap = await axios.post(`${AI_ROOT}/lmstools/MindMap.php`, data, config).then(res => res.data.data)

    return JSON.parse(mindMap)
}


export const buildCourseMindMap = (course) => {
    const mindMap = {
        id: course._id,
        name: course.type.toUpperCase(),
        description: course.title,
        children: course.learningOutcomes.map((lo, loIdx) => {
            return {
                id: lo._id,
                name: `L.O.${loIdx + 1}.`,
                description: lo.statement,
                children: lo.performanceCriteria.map((lt, ltIdx) => {
                    return {
                        id: lt._id,
                        name: `L.T.${loIdx + 1}.${ltIdx + 1}.`,
                        description: lt.description,
                        children: []
                    };
                }),
            };
        }),
    };

    return mindMap
}