import { createSlice } from "@reduxjs/toolkit"
import { cloneDeep } from "lodash";


const dictSlice = createSlice({
    name: 'dict',
    initialState: { translations: null },
    reducers: {
        setDict: (state, action) => {
            state.translations = action.payload;
        },
        clearDict: (state) => {
            state.translations = null;
        },
        updateDict: (state, action) => {
            const { keyword, keywordTranslations } = action.payload
            
            let updated = cloneDeep(state.translations)
            updated[keyword] = keywordTranslations

            state.translations = updated
        },
    },
})

// Extract and export each action creator by name
export const { setDict, clearDict, updateDict } = dictSlice.actions

// selectors
export const getDict = (state) => state.dict.translations;

// Export the reducer, either as a default or named export
export default dictSlice.reducer