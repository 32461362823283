import axios from 'axios';


const askChatGPT = async (prompt) => {
    // ChatGPT request
    const res = await axios.post("https://api.openai.com/v1/chat/completions",
        {
            model: 'gpt-4o',
            response_format: { type: 'json_object' },
            messages: [
                {
                    role: 'system',
                    content: 'You are a helpful assistant designed to output JSON.'
                },
                {
                    role: 'user',
                    content: prompt
                }
            ],
            temperature: 0
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${process.env.OPENAI_API_KEY}`
            }
        }
    );

    // Return the parsed content
    return JSON.parse(res.data.choices[0].message.content);
}


export default askChatGPT