import axios from "axios"
import { SOCKET_ROOT } from "@constants"


export const sendNotification = async (notificationId) => {
    try {
        const action = await axios.post(`${SOCKET_ROOT}/sendNotification`,
            { notificationId },
            {
                headers: {
                    LWE_SECRET: process.env.LWE_SECRET
                }
            }
        )

        return action
    } catch (err) {
        console.error(`error in sendNotification: ${err.message}`)
    }
}