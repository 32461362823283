import { LWE_HMAC } from '@constants';
import axios from 'axios';
import crypto from 'crypto';
import PromiseRetryRequests from "./promise.retry.requests"
import sendEmail from "./sendEmail"
import extractSEOText from "./seo.text"
import uploadToCloud from "./uploadToCloud"
import { NextResponse } from 'next/server';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { sendNotification } from './socket.requests'
import { buildCourseMindMap, buildCourseMindMapAsync } from "./course.mindmap"
import askChatGPT from './askChatGPT';

export {
    askChatGPT,
    buildCourseMindMapAsync,
    buildCourseMindMap,
    PromiseRetryRequests,
    sendEmail,
    extractSEOText,
    uploadToCloud,
    sendNotification,
}

export const ReactSwal = withReactContent(Swal);

export function dev_console_log(...args) {
    if ("development" === process.env.NODE_ENV) {
        console.log(...args);
    }
}

export const AxiosRequest = axios.create({
    withCredentials: true,
    validateStatus: status => (status >= 200 && status < 300) || [422, 409, 403].includes(status)
})


export const calculateAge = (birthdate) => {
    const birthDate = new Date(birthdate);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};


export const getTimeAgo = (dateString) => {
    const targetDate = new Date(dateString);
    const now = new Date();
    const diffInMs = now - targetDate;

    const diffInSeconds = Math.floor(diffInMs / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30); // Approximate months
    const diffInYears = Math.floor(diffInDays / 365); // Approximate years

    let timeAgo;

    if (diffInYears > 0) {
        timeAgo = `${diffInYears} year(s) ago`;
    } else if (diffInMonths > 0) {
        timeAgo = `${diffInMonths} month(s) ago`;
    } else if (diffInWeeks > 0) {
        timeAgo = `${diffInWeeks} week(s) ago`;
    } else if (diffInDays > 0) {
        timeAgo = `${diffInDays} day(s) ago`;
    } else if (diffInHours > 0) {
        timeAgo = `${diffInHours} hour(s) ago`;
    } else if (diffInMinutes > 0) {
        timeAgo = `${diffInMinutes} minute(s) ago`;
    } else {
        timeAgo = `${diffInSeconds} second(s) ago`;
    }

    return timeAgo;
}


export const getHumanReadableDuration = (startDate, endDate) => {
    const msDiff = new Date(endDate) - new Date(startDate); // difference in milliseconds

    // Calculate units
    const seconds = Math.floor(msDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    // Get the remaining hours, minutes, and seconds
    const remainingHours = hours;
    const remainingMinutes = minutes % 60;
    const remainingSeconds = seconds % 60;

    return `${+remainingHours > 0 ? `${remainingHours} hours,` : ''} ${remainingMinutes} minutes, ${remainingSeconds} seconds`.trim();
}


export const cleanWord = (word) => word?.toLowerCase()?.trim(); // word.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").toLowerCase().trim();


export const ServerError = (error) => {
    const errorInfo = {
        message: `Internal Server Error: ${error.message}`,
        path: error.stack?.split("at ")[1].trim() || JSON.stringify(error),
    }

    if(error.name == "AxiosError"){
        errorInfo[error.name] = error.response.data
    }
    
    console.error(errorInfo);
    return NextResponse.json(errorInfo, { status: 500 });
}


// lwe decryption function used in fawaterak
export const decrypt = (encryptedText) => {
    const algorithm = 'aes-256-cbc';
    const textParts = encryptedText.split(':');
    const iv = Buffer.from(textParts.shift(), 'hex');
    const encryptedTextBuffer = Buffer.from(textParts.join(':'), 'hex');

    // Create decipher instance
    const decipher = crypto.createDecipheriv(algorithm, Buffer.from(LWE_HMAC), iv);

    // Decrypt the text
    let decrypted = decipher.update(encryptedTextBuffer, 'hex', 'utf8');
    decrypted += decipher.final('utf8');

    return decrypted;
}

// lwe encryption function used in fawaterak
export const encrypt = (text) => {
    const algorithm = 'aes-256-cbc';
    const iv = crypto.randomBytes(16);

    // Create cipher instance
    const cipher = crypto.createCipheriv(algorithm, Buffer.from(LWE_HMAC), iv);

    // Encrypt the text
    let encrypted = cipher.update(text, 'utf8', 'hex');
    encrypted += cipher.final('hex');

    // Return iv and encrypted text
    return iv.toString('hex') + ':' + encrypted;
}


export const formatNumber = (num) => {
    if (num < 1e3) return num.toString();
    else if (num < 1e6) return (num / 1e3).toFixed(num % 1e3 > 1e2 ? 1 : 0) + 'K';
    else if (num < 1e9) return (num / 1e6).toFixed(num % 1e6 > 1e5 ? 1 : 0) + 'M';
    else if (num < 1e12) return (num / 1e9).toFixed(num % 1e9 > 1e8 ? 1 : 0) + 'B';
    else return (num / 1e12).toFixed(num % 1e12 > 1e11 ? 1 : 0) + 'T';
}


export const fib = (n) => {
    const phi = (1 + Math.sqrt(5)) / 2;  // Golden ratio
    const psi = (1 - Math.sqrt(5)) / 2;  // Conjugate of the golden ratio

    // Binet's formula: F(n) = (phi^n - psi^n) / sqrt(5)
    return Math.round((Math.pow(phi, n) - Math.pow(psi, n)) / Math.sqrt(5));
};


export const calcLevel = (expPoints) => {
    let level = 0;
    let currentLevelPoints = 0;

    while (currentLevelPoints <= expPoints) {
        level++;
        currentLevelPoints = fib(level) * 100;
    }

    return level - 1;
};


export const replacePlaceholders = (str, replacements) => str.replace(/\{\{(\w+)\}\}/g, (match, p1) => replacements[p1] || match)


export function lowerCaseUnderscoredString(input) {
    // Step 1: Convert the string to lower case
    const lowerCaseString = input.toLowerCase();

    // Step 2: Replace spaces with underscores
    const underscoredString = lowerCaseString.replace(/\s+/g, '_');

    return underscoredString;
}

export async function number_abbreviation(params) {
    // console.log("params");
    // dev_console_log(params);
    const { number } = await params;
    // if the  function is getting replaced, place keep the code above 
    if (number >= 1e12) {
        return (number / 1e12).toFixed(1) + 'T';
    } else if (number >= 1e9) {
        return (number / 1e9).toFixed(1) + 'B';
    } else if (number >= 1e6) {
        return (number / 1e6).toFixed(1) + 'M';
    } else if (number >= 1e3) {
        return (number / 1e3).toFixed(1) + 'K';
    } else {
        return (number ?? 0).toString();
    }
}

export function mapPlaceholders(placeholders1, placeholders2 = null) {
    // If placeholders2 is null, use placeholders1
    if (!placeholders2) { placeholders2 = placeholders1; }
    // dev_console_log("placeholders1");
    // dev_console_log(placeholders1);
    // dev_console_log("placeholders2");
    // dev_console_log(placeholders2);
    const result = {}; // Initialize the result object

    for (const key in placeholders1) {
        // dev_console_log("key");
        // dev_console_log(key);
        if (placeholders1.hasOwnProperty(key)) {
            const entry = placeholders1[key];
            // dev_console_log("entry");
            // dev_console_log(entry);

            // Direct value assignment
            if (entry.directValue !== undefined) {
                result[key] = entry.directValue;
                // dev_console_log("result[key]");
                // dev_console_log(result[key]);

                // Placeholder reference resolution
            } else if (entry.placeholder !== undefined) {
                result[key] = placeholders2[entry.placeholder].directValue;
                // dev_console_log("result[key]");
                // dev_console_log(result[key]);

                //  Function resolution
            } else if (entry.fn !== undefined && entry.params !== undefined) {
                // dev_console_log("entry.params");
                // dev_console_log(entry.params);

                const params = mapPlaceholders(entry.params, placeholders1);
                // dev_console_log("params");
                // dev_console_log(params);

                // Evaluate function with resolved parameters
                result[key] = eval(entry.fn)(params);
                // dev_console_log("result[key]");
                // dev_console_log(result[key]);
            }
        }
    }

    return result; // Return the fully mapped result
}